import React from "react"
import * as styles from './style.module.sass'
import { useLocation } from "@reach/router"

import NewsItem from '../../molecules/NewsItem'

const NewsList = (props) => {

  const location = useLocation();

  return(
    <ol className={styles.NewsList}>
      {props.newsList.map((news, id) => (
        <li
          key={id}
          className={
            props.layoutName=='/'? styles.NewsItem + ' ' + styles.NewsItemTop : styles.NewsItem
          }
        >
          <NewsItem
            layoutName={props.layoutName}
            newsItem={news.node}
          />
        </li>
      ))}
    </ol>
  )
}

export default NewsList
