import React, {useRef} from "react"
import * as styles from './style.module.sass'

import SpPageTitle from "../../atoms/SpPageTitle"
import NewsList from "../../organisms/NewsList"
import Footer from "../../organisms/Footer"

const NewsTemplate = (props) => {
  const layoutName = useRef('/news/');

  return (
    <div
      className={styles.container}
      id={"container"}
    >
      <div
        className={styles.containerWrap}
      >
          <SpPageTitle
            title={"News"}
            layoutName={layoutName.current}
          />
          <section className={styles.news}>
            <NewsList
              newsList={props.newsList}
              layoutName={layoutName.current}
            />
          </section>
          <Footer
            layoutName={layoutName.current}
          />
      </div>
    </div>
  )
}

export default NewsTemplate
