import React from 'react'
import * as styles from './style.module.sass'

const NewsItemText = (props) => (
  <div className={
    props.isTop ? styles.NewsItemText + ' ' + styles.NewsItemTextTop : styles.NewsItemText
  }>
    {
      props.isTop ?
        <h3 className={styles.NewsItemTextTitle + ' ' + styles.NewsItemTextTitleIndex}>
          {props.title}
        </h3>
      :
      <h1 className={styles.NewsItemTextTitle}>
        {props.title}
      </h1>
    }
    
    <p className={
      props.isTop ? styles.NewsItemTextDate + ' ' + styles.NewsItemTextDateTop : styles.NewsItemTextDate
    }>
      {props.date}
    </p>
  </div>
)

export default NewsItemText
