import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/organisms/seo"
import NewsTemplate from "../components/template/NewsTemplate"


const IndexPage = ({ data }) => {
  return (
    <>
      <Seo title="News" />
      <NewsTemplate
        newsList={data.newsList.edges}
      />
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    newsList: allWpNews(sort: {order: DESC, fields: date}) {
      edges {
        node {
          title
          slug
          newsContent {
            newsContentImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [480, 720]
                    quality: 100
                    width: 720
                  )
                }
              }
            }
          }
          date(formatString: "YY.MM.DD")
        }
      }
    }
  }
`
