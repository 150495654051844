import React from "react"
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../../atoms/HoverProvider'
import SingleImage from '../../atoms/SingleImage'
import PlaceholderImage from "../../atoms/PlaceholderImage"
import NewsItemText from '../../atoms/NewsItemText'

const NewsItem = (props) => (
  <HoverProvider
      hoverClassName={styles.isHover}
  >
    <Link
      to={`/news/${props.newsItem.slug}`}
      className={styles.NewsItem}
    >
      <div className={styles.NewsItemImage}>
        {props.newsItem.newsContent.newsContentImage
          ?<SingleImage
            layoutName={props.layoutName}
            source={props.newsItem.newsContent.newsContentImage.localFile}
            absolute={true}
            sizes={"(max-width: 640px) 480px, 720px"}
            alt={props.newsItem.title}
            lazy={true}
          />
          :<PlaceholderImage
            layoutName={props.layoutName}
            absolute={true}
            width={'35%'}
          />
        }
      </div>
      <NewsItemText
        layoutName={props.layoutName}
        title={props.newsItem.title}
        date={props.newsItem.date}
        isTop={true}
      />
    </Link>
  </HoverProvider>
);

export default NewsItem
