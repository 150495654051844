import React, {memo} from 'react'
import LogoSvg from '../../../images/logo.svg'
import * as styles from './style.module.sass'

const PlaceholderImage = memo((props) => {
  return(
    <div
      className={
        props.absolute? styles.PlaceholderImage + ' ' + styles.PlaceholderImageAbsolute : styles.PlaceholderImage
      }
    >
      <img
        className={styles.PlaceholderImageSvg}
        style={{width:props.width? props.width : '35%'}}
        src={LogoSvg}
      />
    </div>
  )
})

export default PlaceholderImage
